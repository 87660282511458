import React, { useRef, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { NotificationManager } from 'react-notifications';
import Field from '@modules/Core/Field';
import ReCAPTCHA from 'react-google-recaptcha';
import { StyledRegistrationForm, StyledDomain } from '@modules/Entry/components/forms/RegistrationForm/styles';
import { schema } from '@validation/Entry/registration';
import { createUser } from '@API/users';
import { Turnstile } from '@marsidev/react-turnstile';

const RegistrationForm = () => {
  const [isCaptchaConfirmed, setCaptchaConfirmed] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const widgetRef = useRef(null);

  const captchaConfirmedHandler = (token) => {
    setCaptchaConfirmed(true);
    setCaptchaToken(token);
  };

  const captchaClearHandler = () => {
    setCaptchaConfirmed(false);
    setCaptchaToken('');
    widgetRef.current?.reset();
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      domainName: '',
    },
    onSubmit: async (values) => {
      captchaClearHandler();
      try {
        if (isCaptchaConfirmed && Boolean(captchaToken.length)) {
          await createUser({ ...values, token: captchaToken });
          NotificationManager.success('User created successful', 'Success');
          formik.resetForm();
        }
      } catch (e) {
        const { data, status } = e.response;

        if (status === 409 || status === 403) {
          NotificationManager.error('User creating is failed', 'Fail');
          formik.setErrors({
            [data.field]: data.message,
          });
        }
      }
    },
    validationSchema: schema,
    enableReinitialize: true,
  });

  return (
    <StyledRegistrationForm>
      <h2>Sign up for a Divery demo site</h2>
      <FormikProvider value={formik}>
        <Form>
          <Field
            name="firstName"
            label="FIRST NAME"
            placeholder="Your first name"
            isRequired
          />
          <Field
            name="lastName"
            label="LAST NAME"
            placeholder="Your last name"
            isRequired
          />
          <Field
            name="email"
            label="E-MAIL ADDRESS"
            placeholder="you@email.com"
            isRequired
          />
          <Field
            name="company"
            label="COMPANY"
            placeholder="Company Inc."
          />
          <StyledDomain>
            <Field
              name="domainName"
              label="DOMAIN"
              placeholder="yourdomain"
              isRequired
            />
            <p>
              .demo.divery.io
            </p>
          </StyledDomain>
          <Turnstile
            ref={widgetRef}
            siteKey="0x4AAAAAAAeEXbzhXqI3izzu"
            onSuccess={captchaConfirmedHandler}
            options={{ theme: 'light' }}
          />
          <button type="submit" disabled={!isCaptchaConfirmed && !captchaToken.length}>
            CREATE DEMO SITE
          </button>
        </Form>
      </FormikProvider>
    </StyledRegistrationForm>
  );
};

export default RegistrationForm;
