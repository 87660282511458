import React from 'react';
import { Field as FormikField, useField } from 'formik';
import { StyledField, StyledError } from '@modules/Core/Field/styles';

const Field = ({ label = '', isRequired = false, ...props }) => {
  const [, meta] = useField(props);

  const hasError = meta.touched && meta.error;

  return (
    <StyledField hasError={hasError}>
      <p>
        {label}
        {isRequired ? <span> *</span> : null}
      </p>
      <FormikField {...props}/>
      {hasError ? (
        <StyledError>
          {meta.error}
        </StyledError>
      ) : null}
    </StyledField>
  );
};

export default Field;
