import React from 'react';
import Background from '@assets/images/divery.jpg';
import Logo from '@assets/images/Divery_logo.png';
import { StyledSplashScreen, StyledBackground, StyledLogo } from '@modules/Entry/components/SplashScreen/styles';

const SplashScreen = () => {
  return (
    <StyledSplashScreen>
      <StyledBackground src={Background} alt="Divery"/>
      <StyledLogo src={Logo} alt="Logo"/>
    </StyledSplashScreen>
  );
};

export default SplashScreen;
