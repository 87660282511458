import styled from 'styled-components';

export const StyledSplashScreen = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const StyledBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const StyledLogo = styled.img`
  max-width: 400px;
  padding-top: 100px;
`;
