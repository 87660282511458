import React from 'react';
import Registration from '@modules/Entry/containers/Registration';
import Favicon from 'react-favicon';
import FaviconImage from '@assets/images/favicon-divery.png';
import { NotificationContainer } from 'react-notifications';
import { StyledApp } from './commonStyles';
import 'react-notifications/lib/notifications.css';

const App = () => {
  return (
    <>
      <Favicon url={FaviconImage}/>
      <StyledApp/>
      <Registration/>
      <NotificationContainer/>
    </>
  );
};

export default App;
