import { object, string } from 'yup';

const FIELD_IS_REQUIRED = 'Field is required';

export const schema = object().shape({
  firstName: string()
    .required(FIELD_IS_REQUIRED)
    .max(30),
  lastName: string()
    .required(FIELD_IS_REQUIRED)
    .max(30),
  email: string()
    .required(FIELD_IS_REQUIRED)
    .email(),
  company: string()
    .max(50),
  domainName: string()
    .required(FIELD_IS_REQUIRED)
    .max(30),
});
