import { createGlobalStyle } from 'styled-components';

export const StyledApp = createGlobalStyle`
  body, ul, h4, h5, p, h6 {
    margin: 0;
  }
  
  button {
    border: 0;
    outline: none;
  }
  
  button,
  select {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
`;
