import React from 'react';
import RegistrationForm from '@modules/Entry/components/forms/RegistrationForm';
import SplashScreen from '@modules/Entry/components/SplashScreen';
import { StyledRegistration } from '@modules/Entry/containers/Registration/styles';

const Registration = () => {
  return (
    <StyledRegistration>
      <SplashScreen/>
      <RegistrationForm/>
    </StyledRegistration>
  );
};

export default Registration;
