import styled from 'styled-components';

export const StyledField = styled.div`
  margin-bottom: 25px;
  position: relative;
  
  p {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
    margin-bottom: 10px;
    
    span {
      color: #29bbe2;
    }
  }
  
  input {
    background-color: #ffffff;
    color: #333;
    margin: 0 5px 0 0;
    outline: none;
    width: 100%;
    padding: 12px 0;
    border-width: 0;
    border-bottom: 2px solid ${(props) => (props.hasError ? '#d70707' : '#ccc')};
    transition: .3s all ease;
    font-size: 16px;
    
    &:focus {
      border-color: #39A6DC;
      color: #000;
    }
  }
`;

export const StyledError = styled.span`
  color: #d70707;
  font-size: 14px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
`;
