import styled from 'styled-components';

export const StyledRegistrationForm = styled.div`
  padding: 100px 30px 100px 50px;
  width: 50%;
  
  h2 {
   color: #29bbe2;
   font-size: 40px;
   margin-bottom: 35px;
  }
  
  form {
    max-width: 648px;
    
    button[type='submit'] {
      margin-top: 50px;
      color: #FFF;
      border: 0;
      letter-spacing: 1px;
      padding: 15px 20px;
      font-size: 16px;
      cursor: pointer;
      transition: all .3s ease-in;
      
      &:hover:not(:disabled) {
        background: #118cac;
      }
    }

    button[type='submit']:disabled {
      background: lightgrey;
    }

    button[type='submit']:not(:disabled) {
      background: #29bbe2;
    }
  }
`;

export const StyledDomain = styled.div`
  display: flex;
  align-items: center;
  
  & > p {
    padding-left: 5px;
  }
  
  input {
    text-align: right;
    margin-right: 5px;
  }
`;
